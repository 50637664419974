$(function() {
    _.templateSettings = {
        interpolate: /\{\{(.+?)\}\}/g, // print value: {{ value_name }}
        evaluate: /\{%([\s\S]+?)%\}/g, // excute code: {% code_to_execute %}
        escape: /\{%-([\s\S]+?)%\}/g // excape HTML: {%- <script> %} prints &lt;script&gt;
    };

    var renderItem = function(item, target) {
        switch (item.ItemType) {
            case 'image':
                return new ImageView({model:new Backbone.Model(item)});

            case 'twoColumn':
                return new ColumnView({model:new Backbone.Model(item)});

            case 'text':
                return new TextView({model:new Backbone.Model(item)});

            case 'panes':
                return new PanesView({model:new Backbone.Model(item)});

            case 'sequence':
                return new SequenceView({model:new Backbone.Model(item)});

            case 'video':
                return new VideoView({model:new Backbone.Model(item)});

            case 'audio':
                return  new AudioView({model:new Backbone.Model(item)});

            default:
                return {$el:'Ikke understøttet'};
        }
    };

    var tagParserRegExp = /<(fakta|opgave*\s?)>([\s\S]*?)<\/\1>/ig;
    var iframeTagParserRegExp = /<iframe([^>]*)>[^<]*<\/iframe>/igm;
    var attributeParserRegExp = /([^="'<> ]*)=["']([^"']*)['"]/img;
    var numberAttributes = ['width', 'height'];

    var tagParser = function (string) {
        string = string.replace(
            tagParserRegExp,
            function (match, tag, contents, offset, string) {
                switch (tag) {
                    case 'fakta':
                        return '<div class="snippet snippet--fakta"><div class="ribbon"><span class="ribbon__icon icon-factbox"></span></div>' + contents + '</div>';

                    case 'opgave':
                        return '<div class="snippet snippet--opgave"><div class="ribbon"><span class="ribbon__icon icon-task"></span></div>' + contents + '</div>';

                    default:
                        return '<' + tag + '>' + contents + '<' + tag + '/>';
                }
            }
        );

        string = string.replace(
            iframeTagParserRegExp,
            function (match, attributes, offset, string) {
                var attributeMapping = {};

                attributes = attributes.replace(
                    attributeParserRegExp,
                    function (match, attribute, value, offset, string) {
                        attributeMapping[attribute] = (numberAttributes.indexOf(attribute) !== -1 ? parseInt(value, 10) : value);

                        return attribute + '="' + value + '"';
                    }
                );

                var wrapperStyle = '';

                if (typeof attributeMapping.width !== 'undefined' && typeof attributeMapping.height !== 'undefined') {
                    attributes += ' style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;"';
                    wrapperStyle = 'width: ' + attributeMapping.width + 'px; padding-bottom:' + ((attributeMapping.height / attributeMapping.width) * 100) + '%;';
                }
                else {
                    attributes += ' style="position: absolute; left: 0; top: 0; width: 100%; height: 100%;"';
                    wrapperStyle = 'position: static;';
                }

                attributes = attributes.replace(/\&amp\;/g, '&amp;');

                return '<div class="iframe-wrapper' + (attributes.indexOf('audio') !== -1 ? ' iframe-wrapper--audio' : '') + '"' + (wrapperStyle !== '' ? ' style="' + wrapperStyle + '"' : '') + '><iframe' + attributes + '></iframe></div>';
            }
        );

        return string;
    };

    function findSuitablePlacement (reference, tooltip, event) {
        var width = tooltip.offsetWidth;
        var height = tooltip.offsetHeight;

        var x = (event.pageX - window.scrollX) + 3;
        var y = (event.pageY - window.scrollY) + 3;

        x = Math.min(x, (window.innerWidth - width) - 5);
        y = Math.min(y, (window.innerHeight - height) - 5);

        tooltip.style.cssText = [
            'left: ' + x + 'px;',
            'top: ' + y + 'px;',
        ].join('');

        width = height = x = y = null;
    }

    // Articles
    var Articles = new ArticlesCollection();
    var ArticleHighlightView = Backbone.View.extend({
        className: 'highlight',
        template: _.template($('#highlight').html()),
        initialize: function(params) {
            if (!isMobile) {
                $(window).scrollLeft(0);
            }

            this.$el.data('id',this.model.get('ID'));

            if (params.active) {
                this.$el.data('sec-id',params.active);
            }

            var ref = this;
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );

            _.each(this.model.get('EventItems'), function(item) {
                ref.$el.find('.items').append(renderItem(item).$el);
            });

            if (!globalData.ShowThemeTitleInEvent) {
                this.$el.find('.info p').hide();
            }

            this.$el.find('.ordforklaring').each(function () {
                var $this = $(this),
                    dataOpslag = $this.data('opslag');

                var result = _.find(globalData.Glossary, function(obj) {
                    return obj.Word == dataOpslag;
                });

                dataOpslag = null;

                if (result) {
                    $this.after('<div class="ord-tooltip">' + result.Explanation + '</div>');
                } else {
                    $this.removeClass("ordforklaring");
                }

                result = null;
                $this = null;
            });

            ref.$el.addClass('show');
        },
        events:{
            'mouseover .ordforklaring': function(e) {
                var reference = e.currentTarget;
                var tooltip = $(reference).next()[0];

                findSuitablePlacement(reference, tooltip, e);

                $(document).unbind('mousemove.tooltip').bind('mousemove.tooltip', function (evt) {
                    findSuitablePlacement(reference, tooltip, evt);
                });
            },
            'mouseout .ordforklaring':function(e) {
                findSuitablePlacement(e.currentTarget, $(e.currentTarget).next()[0], e);

                $(document).unbind('mousemove.tooltip');
            }
        }
    });

    var ArticleView = Backbone.View.extend({
        className: 'box',
        template: _.template($('#box').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            ).data('id','model');

            this.$el.addClass(this.model.get('Weight'));

            if (this.model.get('Themes').length) {
                var themeClasses = 'theme ';

                for (var t in this.model.get('Themes')) {
                    themeClasses += themes.findWhere({Title:this.model.get('Themes')[t]}).cid + ' ';
                }

                this.$el.find('.thumb').addClass(themeClasses).css({
                    borderColor: themes.findWhere({Title:this.model.get('Themes')[0]}).get('ColorCode'),
                });
            }
        },
        events:{
            'click .thumb':function() {
                // Show article
                $('.visited').removeClass('visited');
                this.$el.find('.thumb').addClass('visited');
                router.navigate('show/' + this.model.get('ID'), {trigger: true});
            },
            'mouseover':function() {
                // Show hover
                this.$el.parents('.cluster').addClass('active');
                this.$el.addClass('hover');
            },
            'mouseout':function() {
                // Hide hover
                this.$el.parents('.cluster').removeClass('active');
                this.$el.removeClass('hover');
            },
            'mousedown':function() {
                targetModel = this.model;
            },
            'touchstart':function() {
                targetModel = this.model;
            }
        }
    });

    var ListPeriodView = Backbone.View.extend({
        className: 'listitem',
        initialize: function() {
            this.$el.append('<h2>' + this.model.get('Title') + '</h2>');

            if (this.model.get('Subtitle') !== "") {
                this.$el.append('<h3>' + this.model.get('Subtitle') + '</h3>');
            }
        },
        events:{
            'click':function() {
                $('.list-sec .periods .listitem').removeClass('active');
                this.$el.addClass('active');
                $('.list-sec .articles').empty();

                var events = _.clone(this.model.get('Events')).reverse();

                if (events.length > 0 && typeof events[0].ListSortIndex !== 'undefined') {
                    events = _.sortBy(events, function (event) {
                        return event.ListSortIndex;
                    });
                }

                _.each(events, function(curModel) {
                    var view = new ListArticleView({model:new Backbone.Model(curModel)});
                    $('.list-sec .articles').append(view.$el);
                });

                if (isMobile) {
                    router.navigate('list/1', {trigger: true});
                }
            }
        }
    });

    var ListArticleView = Backbone.View.extend({
        className: 'listitem',
        initialize: function() {
            this.$el.append('<h2>' + this.model.get('Title') + '</h2>');

            if (this.model.get('Themes')[0]) {
                this.$el.append('<h3>' + this.model.get('Themes')[0] + '</h3>');
            }

            if (!isMobile) {
                this.$el.hammer();
            }
        },
        events:{
            'click':function() {
                $('.list-sec .articles .listitem').removeClass('active');
                this.$el.addClass('active');
                var highlight = new ArticleHighlightView({model:this.model, el:$('.list-sec .article')});

                if (isMobile) {
                    $(window).scrollTop(0);
                    router.navigate('list/2', {trigger: true});
                }
            },
            'panstart':function(ev) {
                $('.dragger').empty();
                target = ev.target;
                var clone = $(target).clone();
                scrollLeft = $(window).scrollLeft();

                clone.css({
                    'left': ev.gesture.center.x - 120,
                    'top': ev.gesture.center.y - 50,
                    'background':'#fff'
                }).appendTo('.dragger');
            },
            'pan':function(ev) {
                var x = ev.gesture.deltaX + ($(window).scrollLeft() - scrollLeft);

                $('.dragger .listitem').css({
                    'transform': 'translate(' + x + 'px,' + ev.gesture.deltaY + 'px)'
                });
            },
            'panend':function(ev) {
                $('.dragger').empty();
                var drop = document.elementFromPoint(ev.gesture.center.x, ev.gesture.center.y);

                if ($(drop).hasClass('comp1')) {
                    compare1 = this.model.get('ID');
                    $(drop).addClass('active').attr('style', $(target).attr('style'));
                    $(drop).addClass('active').css({
                        'background-image': 'url(http://' + this.model.get('Thumbnail') + ')'
                    });
                }

                if ($(drop).hasClass('comp2')) {
                    compare2 = this.model.get('ID');
                    $(drop).addClass('active').css({
                        'background-image': 'url(http://' + this.model.get('Thumbnail') + ')'
                    });
                }

                if (compare1 && compare2) {
                    $('.comp-box button').bind('click', function() {
                        router.navigate('compare/' + compare1 + '/' + compare2, {trigger: true});
                    }).addClass('active');
                }

                target = null;
            }
        }
    });

    var PeriodView = Backbone.View.extend({
        className: 'cluster',
        template: _.template($('#cluster').html()),
        initialize: function () {
            this.render();
        },
        render: function () {
            var ref = this;
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            ).data('id','model');

            this.$el.attr('data-period-index', this.model.PeriodIndex);

            this.$el.find('.cluster-bg').css('backgroundColor', this.model.get('BackgroundColor')).fadeTo(0, this.model.get('BackgroundImage').Opacity/100);

            var events = _.clone(this.model.get('Events')).reverse();

            if (events.length > 0 && typeof events[0].ListSortIndex !== 'undefined') {
                events = _.sortBy(events, function (event) {
                    return event.ListSortIndex;
                });
            }

            events.reverse();

            _.each(events, function(error, data) {
                var newModel = new ArticleModel(ref.model.get('Events')[data]);
                var article = new ArticleView({model:newModel});

                ref.$el.find('.boxes').append(article.$el);
            });

            ref.$el.find('.boxes').addClass('period-' + ref.model.PeriodIndex);

            ref.$el.find('.boxes').append($('<div class="clear" />'));
            return this;
        }
    });

    function ArrowCallback(e) {
        e.preventDefault();

        var $this = $(this);

        if ($this.hasClass('active')) {
            $this.parents('.sequence').find('.tabs > div:eq(' + $this.attr('data-activate') + ')').click();
        }

        $this = null;
    }

    var ImageView = Backbone.View.extend({
        className: 'image',
        template: _.template($('#image').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );

            if (this.model.get('InsideSequence') == 1) {
                var SequenceIndex = this.model.get('SequenceIndex');
                var SequenceLastIndex = this.model.get('SequenceLastIndex');

                var $arrowLeft = $('<div class="arrow-left' + (SequenceLastIndex !== 0 && SequenceIndex !== 0 ? ' active' : '') + '" data-activate="' + (SequenceIndex - 1) + '" />');
                var $arrowRight = $('<div class="arrow-right' + (SequenceLastIndex !== 0 && SequenceIndex !== SequenceLastIndex ? ' active' : '') + '" data-activate="' + (SequenceIndex + 1) + '" />');

                $arrowLeft.click(ArrowCallback);
                $arrowRight.click(ArrowCallback);

                this.$el.append($arrowLeft);
                this.$el.append($arrowRight);
            }

            if (this.model.get('AllowZooming')) {
                this.$el.addClass('zoom');

                this.$el.data('Title', this.model.get('Title'));
                this.$el.data('Source', this.model.get('Source'));

                this.$el.find('.img').on('click', _.bind(function () {
                    var pictureList = [];
                    var $sequence = this.$el.parents('.sequence');
                    var showImageIndex = 0;
                    var source = this.model.get('Source');

                    if ($sequence.length > 0) {
                        $sequence.find('.image.zoom').each(function (imageIndex) {
                            var $this = $(this);

                            if ($this.data('Source') == source) {
                                showImageIndex = imageIndex;
                            }

                            pictureList.push({
                                title: $this.data('Title'),
                                content: {
                                    type: 'image',
                                    src: 'http://' + $this.data('Source')
                                }
                            });

                            $this = null;
                        });
                    }

                    if (pictureList.length > 0) {
                        new gLightbox(
                            pictureList,
                            showImageIndex
                        );
                    }
                    else {
                        new gLightbox({
                            title: this.model.get('Title'),
                            content: {
                                type: 'image',
                                src: 'http://' + source
                            }
                        });
                    }
                }, this));
            }
        }
    });

    var ColumnView = Backbone.View.extend({
        className: 'column',
        template: _.template($('#column').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
            var ref = this;

            _.each(this.model.get('Columns')[0].Items, function(item) {
                ref.$el.find('.col1').append(renderItem(item).$el);
            });

            _.each(this.model.get('Columns')[1].Items, function(item) {
                ref.$el.find('.col2').append(renderItem(item).$el);
            });

            if (this.model.get('InsideSequence') == 1) {
                var SequenceIndex = this.model.get('SequenceIndex');
                var SequenceLastIndex = this.model.get('SequenceLastIndex');

                var $arrowLeft = $('<div class="arrow-left' + (SequenceLastIndex !== 0 && SequenceIndex !== 0 ? ' active' : '') + '" data-activate="' + (SequenceIndex - 1) + '" />');
                var $arrowRight = $('<div class="arrow-right' + (SequenceLastIndex !== 0 && SequenceIndex !== SequenceLastIndex ? ' active' : '') + '" data-activate="' + (SequenceIndex + 1) + '" />');

                $arrowLeft.click(ArrowCallback);
                $arrowRight.click(ArrowCallback);

                this.$el.append($arrowLeft);
                this.$el.append($arrowRight);
            }
        }
    });

    var TextView = Backbone.View.extend({
        className: 'text',
        template: _.template($('#text').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
        }
    });

    var PanesView = Backbone.View.extend({
        className: 'panes',
        template: _.template($('#panes').html()),
        initialize: function() {
            var selected = false;
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
            var ref = this;

            _.each(this.model.get('PaneElements'), function(item, i) {
                var btn = $('<div>').data('id',item.ID);

                if (item.Title) {
                    btn.text(item.Title);
                } else {
                    btn.text('Faneblad ' + (i+1));
                }

                ref.$el.find('.tabs').append(btn);
                var paneItems = $('<div>').hide();

                _.each(item.PaneItems, function(innerItems) {
                    paneItems.append(renderItem(innerItems).$el);
                });

                ref.$el.find('.tabs-con').append(paneItems);

                if ($('.highlight').data('sec-id') && $('.highlight').data('sec-id') == item.ID) {
                    selected = true;
                    paneItems.show();
                    btn.addClass('active');
                    paneItems.addClass('active');
                }
            });

            if (!selected) {
                this.$el.find('.tabs-con>div').eq(0).addClass('active');
                this.$el.find('.tabs>div').eq(0).addClass('active');
            }
        },
        events: {
            'click .tabs>div':function(e) {
                this.$el.find('.tabs>div').removeClass('active');
                this.$el.find('.tabs>div').eq($(e.currentTarget).index()).addClass('active');

                if (Backbone.history.getFragment().split('/')[0] == 'show') {
                    router.navigate('show/' + $('.highlight').data('id') + '/active/' + this.$el.find('.tabs>div').eq($(e.currentTarget).index()).data('id'), {trigger: false, replace: true});
                }

                this.$el.find('.tabs-con>div.active').removeClass('active');
                this.$el.find('.tabs-con>div').eq($(e.currentTarget).index()).addClass('active');
            }
        }
    });

    var SequenceView = Backbone.View.extend({
        className: 'sequence',
        template: _.template($('#sequence').html()),
        initialize: function() {
            var selected = false;
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
            var ref = this;

            var SequenceElements =  this.model.get('SequenceElements');

            _.each(SequenceElements, function(item, i) {
                var btn = $('<div />').css({backgroundImage:'url(//' + item.Thumbnail + ')'}).data('id',item.ID);
                var con = $('<div></div>');

                ref.$el.find('.tabs').append(btn);

                _.each(item.SequenceItems, function(seqItem) {
                    seqItem.InsideSequence = 1;
                    seqItem.SequenceIndex = i;
                    seqItem.SequenceLastIndex = SequenceElements.length - 1;

                    con.append(renderItem(seqItem).$el);
                });

                ref.$el.find('.tabs-con').append(con);

                if ($('.highlight').data('sec-id') && $('.highlight').data('sec-id') == item.ID) {
                    selected = true;
                    con.addClass('active');
                    btn.addClass('active');
                }
            });

            this.scroll = this.$el.find('.tabs-scroll');
            this.scrollCon = this.$el.find('.tabs');
            this.prev = this.$el.find('.prev');
            this.next = this.$el.find('.next');

            ref.$el.find('.tabs').width(this.model.get('SequenceElements').length * 134);

            if (!selected) {
                this.$el.find('.tabs-con>div').eq(0).addClass('active');
                this.$el.find('.tabs>div').eq(0).addClass('active');
            }

            $(window).bind('resize.app', _.bind(this.resize, this));
            setTimeout(_.bind(this.resize, this), 10);
        },
        resize:function(e) {
            var w = this.scroll.width();
            var iw = this.$el.find('.tabs').width();
            var sw = this.scroll.scrollLeft();

            if (iw < w) {
                this.$el.find('.prev, .next').removeClass('active');
            } else {
                if (this.scroll.scrollLeft() === 0) {
                    this.prev.removeClass('active');
                }
                else {
                    this.prev.addClass('active');
                }

                if (this.scroll.scrollLeft()+this.scroll.width() <= this.scrollCon.width()-30) {
                    this.next.addClass('active');
                }
                else {
                    this.next.removeClass('active');
                }
            }
        },
        events: {
            'click .tabs>div':function(e) {
                this.$el.find('.tabs>div').removeClass('active');
                this.$el.find('.tabs>div').eq($(e.currentTarget).index()).addClass('active');

                if (Backbone.history.getFragment().split('/')[0] == 'show') {
                    router.navigate('show/' + $('.highlight').data('id') + '/active/' + this.$el.find('.tabs>div').eq($(e.currentTarget).index()).data('id'), {trigger: false, replace: true});
                }

                this.$el.find('.tabs-con>div.active').removeClass('active');
                this.$el.find('.tabs-con>div').eq($(e.currentTarget).index()).addClass('active');
            },
            'click .next':function(e) {
                var scroll = this.scroll.scrollLeft() + this.scroll.width() - 175;

                $(this.scroll).animate({scrollLeft: scroll}, 400, _.bind(this.resize, this));
            },
            'click .prev':function(e) {
                var scroll = this.scroll.scrollLeft() - this.scroll.width() - 175;

                $(this.scroll).animate({scrollLeft: scroll}, 400, _.bind(this.resize, this));
            }
        }
    });

    var VideoView = Backbone.View.extend({
        className: 'video',
        template: _.template($('#video').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );

            const twentyThreeVideo = this.model?.attributes?.TwentyThreeVideo;

            if (twentyThreeVideo) {
                const twentyThreeWidth = parseInt(
                    twentyThreeVideo?.OriginalWidth ?? ""
                );
                const twentyThreeHeight = parseInt(
                    twentyThreeVideo?.OriginalHeight ?? ""
                );

                const twentyThreeAspectRatio =
                    Number.isNaN(twentyThreeHeight) || Number.isNaN(twentyThreeWidth)
                    ? undefined
                    : (`${(twentyThreeHeight / twentyThreeWidth) * 100}%`);
                
                console.log(twentyThreeHeight, twentyThreeVideo, twentyThreeWidth, twentyThreeAspectRatio);

                if (twentyThreeAspectRatio && this.$el[0]) {
                    this.$el[0].style.paddingBottom = twentyThreeAspectRatio;
                }
            }
        }
    });

    var AudioView = Backbone.View.extend({
        className: 'audio',
        template: _.template($('#audio').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
        }
    });

    var SeachItemView = Backbone.View.extend({
        className: 'search-item',
        template: _.template($('#search-item').html()),
        initialize: function() {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
        },
        events:{
            'click':function() {
                router.navigate('show/' + this.model.get('ID'), {trigger: true});
            }
        }
    });

    var ScrollItemView = Backbone.View.extend({
        className: 'btn-wrap',
        template: _.template($('#scroll-item').html()),
        initialize: function(e) {
            this.$el.html(
                tagParser(
                    this.template(this.model.toJSON())
                )
            );
            this.$el.width((this.model.get('NumberOfColumns')/e.w)*100 + '%');
        },
        events:{
            'click':function(e) {
                $(window).scrollLeft($('.cluster:eq(' + this.model.collection.indexOf(this.model) + ')').offset().left - (($(window).width()*0.50) - $('.cluster:eq(' + this.model.collection.indexOf(this.model) + ')').width()*0.5 ));

                setTimeout(function () {
                    var $target = $(document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2));

                    if (!$target.hasClass('cluster')) {
                        $target = $target.parents('.cluster');
                    }

                    if ($target.hasClass('cluster')) {
                        Filter.clear('period-view');
                        Filter.add('period-view', $target.attr('data-period-index'));
                        Filter.apply(true);
                    }
                }, 10);
            }
        }
    });

    // Load data
    var globalData;
    var Periodes;
    var timelineId;
    var external;
    var lastX = 0;
    var isMobile = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    var isTouch = /Android|webOS|iPhone|iPod|BlackBerry|iPad/i.test(navigator.userAgent);
    var mobileLoaded = false;
    var urlVars = location.search.substring(1).split('&');

    _.each(urlVars, function(e, i) {
        e = e.split('=');
        if (e[0] == 'id') {
            timelineId = e[1];
        }
        if (e[0] == 'external') {
            external = true;
        }
    });

    var Filter = new function () {
        this.filters = {};

        this.initialize = function () {
            this.clear('type');
            this.clear('period');

            var fragment = location.hash.substr(1);

            if (fragment.indexOf('filter') !== -1) {
                fragment = fragment.split('/');

                if (fragment.length > 0) {
                    _.each(fragment, function (filter) {
                        filter = filter.split(':');

                        if (filter.length === 2) {
                            var values = filter[1].split(',');

                            _.each(values, function (value) {
                                Filter.add(filter[0], ((filter[0] == 'period' || filter[0] == 'period-view') ? parseInt(value, 10) : value));
                            });
                        }
                    });
                }
            }
        };

        this.clear = function (filter) {
            if (typeof this.filters[filter] !== 'undefined') {
                delete this.filters[filter];
            }
        };

        this.add = function (filter, item) {
            item = ((filter === 'period' || filter === 'period-view') ? parseInt(item, 10) : item);

            if (typeof this.filters[filter] === 'undefined') {
                this.filters[filter] = [];
            }

            if (this.filters[filter].indexOf(item) === -1) {
                this.filters[filter].push(
                    item
                );
            }
        };

        this.remove = function (filter, item) {
            item = ((filter === 'period' || filter === 'period-view') ? parseInt(item, 10) : item);

            if (typeof this.filters[filter] !== 'undefined' && this.filters[filter].indexOf(item) !== -1) {
                this.filters[filter].splice(
                    this.filters[filter].indexOf(item),
                    1
                );
            }
        };

        this.apply = function (silent) {
            silent = (typeof silent !== 'undefined' ? silent : false);

            var filterString = [];

            _.each(this.filters, function (filters, filter) {
                if (typeof filter !== 'undefined') {
                    filterString.push(filter + ':' + filters.join(','));
                }
            });

            if (silent === false) {
                $('.box, .boxes').removeClass('dim');

                if (typeof this.filters.period !== 'undefined' && this.filters.period.length > 0) {
                    $('.period-filter').removeClass('sel');

                    var notPeriodString = [];

                    _.each(this.filters.period, function (period) {
                        notPeriodString.push('.period-' + period);

                        $('.period-filter-' + period).addClass('sel');
                    });

                    $('.boxes').not(notPeriodString.join(',')).addClass('dim');
                }
                else {
                    $('.period-filter').removeClass('sel');
                    $('.period-filter-all').addClass('sel');
                }

                if (typeof this.filters.type !== 'undefined' && this.filters.type.length > 0) {
                    $('.type-filter').removeClass('sel');

                    var notString = [];

                    _.each(this.filters.type, function (filter) {
                        notString.push('.' + filter);

                        $('.type-filter-' + filter).addClass('sel');
                    });

                    $('.boxes:not(.dim) .thumb').not(notString.join(',')).parent().addClass('dim');
                }
                else {
                    $('.type-filter').removeClass('sel');
                    $('.type-filter-all').addClass('sel');
                }
            }

            var fragment = location.hash.substr(1);

            if (fragment === '' || fragment.indexOf('filter') !== -1) {
                if (filterString.length === 0) {
                    router.navigate('', {
                        trigger: false
                    });
                }
                else {
                    router.navigate(filterString.join('/') + '/filter', {
                        trigger: false
                    });
                }
            }
        };
    };

    var TimelineRouter = Backbone.Router.extend({
        routes: {
            '': 'timelineview',
            'list': 'listview',
            'list/:col': 'mobilelistview',
            'show/:id': 'showid',
            'show/:id/active/:sec': 'showid',
            'compare/:id1/:id2': 'compareview',
        },
        initialize: function(options) {
            this.route(/^(.*?)\/filter/, "timelineview");
        },
        timelineview: function(filter) {
            if (isMobile) {
                router.navigate('list/0', {trigger: true});
            } else {
                $(window).trigger('load');
                $('.top-bar h1').addClass('hidden');
                $('.highlight, .compare-left, .compare-right').empty();
                $('.compare, .list-sec').hide();
                $('.highlight').removeClass('show');
                $('.btn, .filter-bar').removeClass('active active-after');
                $('.top-bar h1').addClass('hidden');
                $('.timeline').show();
                $('.list-sec .periods, .list-sec .articles, .list-sec .article').empty();
                $('.top-bar .period').text('');
                resize();
                $(window).scrollLeft(lastX);

                Filter.apply();
            }
        },
        listview: function() {
            lastX = $(window).scrollLeft();
            $('.highlight, .compare-left, .compare-right').empty();
            $('.btn.list').addClass('active');
            $('.top-bar h1').removeClass('hidden');
            $('.btn, .filter-bar').removeClass('active active-after');
            $('.timeline, .compare').hide();
            $('.highlight').removeClass('show');
            $('.list-sec').show();
            $('.list-sec .article').empty();

            Periodes.each(function(curModel) {
                var view = new ListPeriodView({model:curModel});
                $('.list-sec .periods').append(view.$el);
            });
        },
        mobilelistview: function(col) {
            if (!mobileLoaded) {
                router.navigate('list/0', {trigger: false});
                col = 0;
            }

            mobileLoaded = true;

            $('.timeline, .compare').hide();
            $('.highlight').removeClass('show');
            $('.list-sec').show();

            if ($('.list-sec .periods').is(':empty')) {
                Periodes.each(function(curModel) {
                    var view = new ListPeriodView({model:curModel});
                    $('.list-sec .periods').append(view.$el);
                });
            }

            $('.list-sec > div:not(:eq(' + col + '))').hide();
            $('.list-sec > div:eq(' + (col) + ')').show();

            if (col > 0) {
                $('.white h1').removeClass('hidden');
                $('.white h1 .title').text($('.periods .active h2').text()).show();
            } else {
                $('.white h1').addClass('hidden');
                $('.white h1 .title').text(globalData.Title).show();
            }
        },
        showid: function(id, sec) {
            lastX = $(window).scrollLeft();
            $('.timeline, .list-sec, .compare').hide();

            new ArticleHighlightView({model:Articles.findWhere({ID:id}), active:sec, el:$('.highlight')});

            $('.top-bar h1').removeClass('hidden');
            $('.btn, .filter-bar').removeClass('active active-after');
            $('.top-bar .period').text(' - ' + Articles.findWhere({ID:id}).get('period'));
            $('.list-sec .periods, .list-sec .articles, .list-sec .article').empty();
        },
        compareview: function(id1, id2) {
            lastX = $(window).scrollLeft();

            $('.highlight, .compare-left, .compare-right').empty();
            $('.list-sec, .timeline').hide();
            $('.highlight').removeClass('show');
            $('.compare').show();
            $('.top-bar h1').removeClass('hidden');
            $('.btn, .filter-bar').removeClass('active active-after');
            $('.list-sec .periods, .list-sec .articles, .list-sec .article').empty();

            new ArticleHighlightView({model:Articles.findWhere({ID:id1}), el:$('.compare-left')});
            new ArticleHighlightView({model:Articles.findWhere({ID:id2}), el:$('.compare-right')});
        }
    });

    var router = new TimelineRouter();
    var themes;

    function loadData(d) {
        Filter.initialize();

        globalData = d;
        themes = new Backbone.Collection(d.Themes.Items);

        if (themes.length > 0) {
            var themeDropdown = $('<div />').addClass('dropdown');
            var label = $('<label />');

            if (d.Themes.Label !== "") {
                label.text(d.Themes.Label);
            }
            else {
                label.text('Type');
            }

            label.append('<span class="arrow icon-arrow-down"></span>');

            label.click(function() {
                $(this).parent().toggleClass('active');
            });

            themeDropdown.append(label);

            var a = $('<div class="type-filter type-filter-all"><div class="radio"><div></div></div>Alle</div>').click(function() {
                Filter.clear('type');
                Filter.apply();
            });

            var list = $('<div />').addClass('list');

            list.append(a);

            themes.each(function(theme) {
                var $themeDropdownItem = $('<div class="type-filter type-filter-' + theme.cid + '"><div class="radio"><div></div></div>' + theme.get('Title') + '<div class="dot" style="background-color:' + theme.get('ColorCode') + '"></div></div>');

                $themeDropdownItem.click(function() {
                    var isActive = ($(this).hasClass('sel') ? false : true);

                    if (isActive) {
                        Filter.add('type', theme.cid);
                    }
                    else {
                        Filter.remove('type', theme.cid);
                    }

                    Filter.apply();
                });

                list.append($themeDropdownItem);
            });

            themeDropdown.append(list);
            $('.filter-bar').append(themeDropdown);
        }

        if (typeof d.TimePeriods !== 'undefined' && d.TimePeriods.length > 0) {
            var $periodDropdown = $('<div />').addClass('dropdown');
            var $periodLabel = $('<label />');

            $periodLabel.text(((typeof d.TimePeriodsLabel !== 'undefined' && d.TimePeriodsLabel !== '') ? d.TimePeriodsLabel : 'Periode'));

            $periodLabel.append('<span class="arrow icon-arrow-down"></span>');

            $periodLabel.click(function() {
                $(this).parent().toggleClass('active');
            });

            $periodDropdown.append($periodLabel);

            var $all = $('<div class="period-filter period-filter-all"><div class="radio"><div></div></div>Alle</div>').click(function() {
                Filter.clear('period');
                Filter.apply();
            });

            var $list = $('<div class="list" />');

            $list.append($all);

            _.each(d.TimePeriods, function (TimePeriod, TimePeriodIndex) {
                var $periodDropdownItem = $('<div class="period-filter period-filter-' + TimePeriodIndex + '"><div class="radio"><div></div></div>' + TimePeriod.Title + '</div>');

                $periodDropdownItem.click(function() {
                    var isActive = ($(this).hasClass('sel') ? false : true);

                    if (isActive) {
                        Filter.add('period', TimePeriodIndex);
                    }
                    else {
                        Filter.remove('period', TimePeriodIndex);
                    }

                    Filter.apply();
                });

                $list.append($periodDropdownItem);
            });

            $periodDropdown.append($list);
            $('.filter-bar').append($periodDropdown);
        }

        if ($('.filter-bar .dropdown').length === 0) {
            $('.buttons').addClass('hide-filter');
        }

        if (d.MarkerColor) {
            var css = document.createElement('style');

            css.type = 'text/css';
            css.innerHTML = '.sequence .tabs>div.active, .thumb.visited { border-color: #' + d.MarkerColor + ' !important}';
            css.innerHTML += '.top-bar .comp-box button { background-color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.top-bar .filter-bar { background-color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.listitem.active h2 { color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.radio div { background-color: #' + d.MarkerColor + ' !important }';
            css.innerHTML += '.highlight .header .info h2 { color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.ordforklaring, .panes .tabs>div.active { color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.compact .header>.info h2 { color: #' + d.MarkerColor + ' }';
            css.innerHTML += '.top-bar .btn.active #icon, .top-bar .btn:hover #icon{ fill: #' + d.MarkerColor + ' }';
            css.innerHTML += '.top-bar .btn.active #border, .top-bar .btn:hover #border{ stroke: #' + d.MarkerColor + ' }';
            css.innerHTML += '.dropdown.active label .arrow { color: #' + d.MarkerColor + ' !important; }';
            css.innerHTML += '.timeline .scroll .cur { background: ' + convertHex(d.MarkerColor, 20) + '; border-color: #' + d.MarkerColor + '}';
            css.innerHTML += '#scroll-eye, #next-arrow, #prev-arrow { fill: #' + d.MarkerColor + ' !important }';
            css.innerHTML += '.ribbon::before { border-top-color: #' + d.MarkerColor + ' !important; }';
            css.innerHTML += '.highlight a, p a, li a { color: #' + d.MarkerColor + '; }';

            if (typeof d.FontFamily !== 'undefined' && typeof d.FontFamily.Title !== 'undefined' && d.FontFamily.Title !== '') {
                css.innerHTML += 'h1, h2, h3, h4, h5, h6 { font-family: ' + d.FontFamily.Title + ' !important; }';
            }

            if (typeof d.FontFamily !== 'undefined' && typeof d.FontFamily.Body !== 'undefined' && d.FontFamily.Body !== '') {
                css.innerHTML += 'body, input { font-family: ' + d.FontFamily.Body + ' !important; }';
            }

            document.body.appendChild(css);
        }

        Periodes = new PeriodsCollection(d.TimePeriods);
        var totalPeriodWidth = 0;

        Periodes.each(function(curModel, PeriodIndex) {
            _.each(curModel.get('Events'), function(i, num) {
                curModel.get('Events')[num].period = curModel.get('Title');
            });

            totalPeriodWidth += curModel.get('NumberOfColumns');
            Articles.add(curModel.get('Events'));

            curModel.PeriodIndex = PeriodIndex;

            var view = new PeriodView({model:curModel});

            if (!isMobile) {
                $('body .timeline').append(view.$el);
            }
        });

        var $scrollFull = $('.scroll .full');

        Periodes.each(function(curModel) {
            var scrollBtnz = new ScrollItemView({model:curModel, w:totalPeriodWidth});
            var scrollBtn = $('<div />').addClass('btn-wrap').width(((curModel.get('NumberOfColumns') / totalPeriodWidth) * 100) + '%');

            $scrollFull.append(scrollBtnz.$el);
        });

        $scrollFull = null;

        if (!d.VisibleTimePeriodeTitleAndSubtitle) {
            $('.cluster').addClass('notitle');
        }

        if (!d.ShowBreadCrumb) {
            $('.top-bar .title, .top-bar .period').hide();
        }

        $('body').css('background', d.BackgroundColor);

        if (!isMobile) {
            var w = 3;

            $('.cluster').each(function(i, d) {
                w += $(d).outerWidth(true);
            });

            $('.timeline').width(w);

            $('h1 .title').text(d.Title);
            $('.top-bar .white').css('background-color', '#' + d.BannerBackgroundColor);

            if (d.BannerBackgroundImage.Source) {
                $('.top-bar .white .banner-bg').css({'background-image':'url(http://' + d.BannerBackgroundImage.Source + ')'}).fadeTo(0, d.BannerBackgroundImage.Opacity/100);
            }

            if (d.BackgroundImage.Source) {
                $('.bg').css({'background-image':'url(http://' + d.BackgroundImage.Source + ')'}).fadeTo(0, d.BackgroundImage.Opacity/100);
            }

            $('.cluster:last .info').addClass('reverse');
            $('.timeline .scroll').addClass('active');

            setTimeout(function () {
                if (typeof Filter.filters['period-view'] !== 'undefined') {
                    $('.timeline .btn-wrap:eq(' + parseInt(Filter.filters['period-view'][0], 10) + ')').click();
                }
            }, 100);
        }

        Backbone.history.start();

        $('.loader').addClass('hide');
        $('.loader-text').addClass('hide');
        $(".lazy").Lazy({
            scrollDirection: 'vertical',
            effect: 'fadeIn',
        });
    }

    if (data.Title) {
        loadData(data);
    } else {
        if (external) {
            $.getJSON('https://tidslinjer.gyldendal.dk/api/timeline/get/' + timelineId, loadData);
        } else {
            $.getJSON('/api/timeline/get/' + timelineId, loadData);
        }
    }

    // Backbutton
    if (!isMobile) {
        $('.top-bar h1').click(function() {
            router.navigate('', {trigger: true});
        });
    } else {
        $('.top-bar h1').click(function() {
            window.history.back();
        });
    }

    $('.btn.list').bind('click', function() {
        var curPath = Backbone.history.getFragment();
        if (curPath == 'list') {
            router.navigate('', {trigger: true});
            $(this).removeClass('active');
        } else {
            router.navigate('list', {trigger: true});
            $(this).addClass('active');
        }
    });

    $('.btn.comp').bind('click', function(e) {
        $('.btn, .filter-bar').not(this).removeClass('active active-after');
        $(this).toggleClass('active');
    });

    $('.btn.find').bind('click', function(e) {
        $('.btn, .filter-bar').not(this).removeClass('active active-after');
        $(this).toggleClass('active');
        $('.find-box input').focus();
    });

    $('.btn.filter').bind('click', function(e) {
        $('.btn').not(this).removeClass('active');
        // $('.box').removeClass('dim');
        $(this).toggleClass('active');
        $('.filter-bar').toggleClass('active');

        if ($('.filter-bar').hasClass('active')) {
            setTimeout(function () {
                $('.filter-bar').addClass('active-after');
            }, 175);
        }
        else {
            $('.filter-bar').removeClass('active-after');
        }
    });

    $('.find-box, .comp-box').bind('click', function(e) {
        e.stopPropagation();
    });

    // Drag'n'drop
    var target = false;
    var targetModel;
    var compare1;
    var compare2;
    var scrollLeft = 0;
    var scrollBarCur = 0;
    var scrolling = false;

    if (!isMobile) {
        var timeline = new Hammer($('.timeline')[0], {touchAction: 'pan-x'});
        timeline.get('pan').set({threshold:0});

        timeline.on('panstart', function(ev) {
            $('.dragger').empty();

            if ($(ev.target).hasClass('thumb')) {
                target = ev.target;
                ev.preventDefault();

                $(target).parents('.cluster').removeClass('active');

                var clone = $(target).clone();
                scrollLeft = $(window).scrollLeft();

                clone.css({
                    'left': ev.center.x - $('.thumb').width()/4,
                    'top': ev.center.y - $('.thumb').width()/4
                }).appendTo('.dragger');
            }

            if ($(ev.target).hasClass('cur')) {
                ev.preventDefault();
                var range = $('.scroll .full').width() - $('.scroll .cur').width();
                var x = $(window).scrollLeft() / (w-$(window).width());
                scrolling = true;
                scrollBarCur = range * x;
            }
        });

        timeline.on('pan', function(ev) {
            if (typeof target != 'undefined') {
                var x = ev.deltaX + ($(window).scrollLeft() - scrollLeft);

                $(target).css('opacity', 0.5);

                $('.dragger .thumb').css({
                    'transform': 'translate(' + x + 'px,' + ev.deltaY + 'px)'
                });
            }

            if (scrolling && !isTouch) {
                ev.preventDefault();
                var range = $('.scroll .full').width() - $('.scroll .cur').width();
                var x2 = scrollBarCur + ev.deltaX;

                $(window).scrollLeft((x2/range)*(w - $(window).width()));
            }

            var $target = $(document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2));

            if (!$target.hasClass('cluster')) {
                $target = $target.parents('.cluster');
            }

            if ($target.hasClass('cluster')) {
                Filter.clear('period-view');
                Filter.add('period-view', $target.attr('data-period-index'));
                Filter.apply(true);
            }
        });

        timeline.on('panend', function(ev) {
            if (typeof target != 'undefined' && targetModel) {
                $(target).css('opacity', 1);
                $('.dragger').empty();

                var drop = document.elementFromPoint(ev.center.x, ev.center.y);

                if ($(drop).hasClass('comp1')) {
                    compare1 = targetModel.get('ID');
                    $(drop).addClass('active').attr('style', $(target).attr('style'));
                }

                if ($(drop).hasClass('comp2')) {
                    compare2 = targetModel.get('ID');
                    $(drop).addClass('active').attr('style', $(target).attr('style'));
                }

                if (compare1 && compare2) {
                    $('.comp-box button').bind('click', function() {
                        router.navigate('compare/' + compare1 + '/' + compare2, {trigger: true});
                    }).addClass('active');
                }
                target = null;
            }

            if (scrolling) {
                var range = $('.scroll .full').width() - $('.scroll .cur').width();
                var x = scrollBarCur + ev.deltaX;

                if (x > 0 && x < range) {
                    scrollBarCur = x;
                }

                scrolling = false;
            }
        });
    }

    $('input').keyup(function() {
        var search = $('input').val().trim().toLowerCase();

        if (search.length >= 1) {
            var result = Articles.filter(function(model) {
                if (
                    String(model.attributes.Title).toLowerCase().includes(search) ||
                    String(model.attributes.Description).toLowerCase().includes(search)
                ) {
                    // fast-match if title / description includes a match
                    return true;
                }

                if (
                    model.attributes.EventItems &&
                    Array.isArray(model.attributes.EventItems)
                ) {
                    // ... otherwise check if one of the content items contains
                    // the search string
                    return !!model.attributes.EventItems.find(function (item) {
                        return searchItemContent(item, search);
                    });
                }

                // if we get here, no match was found
                return false;
            });

            $('.find-box .list').empty();

            for(var i = 0; i < 10; i++) {
                if (result[i]) {
                    var item = new SeachItemView({model:result[i]});
                    $('.find-box .list').append(item.$el);
                }
            }

            if (result.length === 0) {
                $('.find-box .list').html('<div class="noresult">Ingen resultater</div>');
            }
        } else {
            $('.find-box .list').empty();
        }
    });

    Hammer.on($('.compare-left')[0], 'scroll', function() {
        if ($(this).scrollTop() > $(this).find('.header').height() - $(this).find('.header .info').outerHeight()) {
            $(this).addClass('fixed');
            $(this).find('.items').css({
                paddingTop: $(this).find('.header').height() + 20
            });
        } else {
            $(this).removeClass('fixed');
            $(this).find('.items').css({
                paddingTop: 20
            });
        }
    });

    Hammer.on($('.compare-right')[0], 'scroll', function() {
        if ($(this).scrollTop() > $(this).find('.header').height() - $(this).find('.header .info').outerHeight()) {
            $(this).addClass('fixed');
            $(this).find('.items').css({
                paddingTop: $(this).find('.header').height() + 20
            });
        } else {
            $(this).removeClass('fixed');
            $(this).find('.items').css({
                paddingTop: 20
            });
        }
    });

    var w = 0;

    function resize() {
        if (!isMobile) {
            w = 3;

            $('.cluster').each(function(i, d) {
                w += $(d).outerWidth(true) + 1;
            });

            $('.timeline').width(w);

            var range = $('.scroll .full').width() - $('.scroll .cur').width();
            var x = $(window).scrollLeft() / (w-$(window).width());

            $('.scroll .cur').css({
                'left': x * range + 'px',
                'width': ($(window).width()/w) * 100 + '%'
            });

            if ($(window).width()/w > 1) {
                $('.scroll .cur').css({
                    'left': '0px',
                    'width': '100%'
                });
            }
        }
    }

    $(".lazy").Lazy({
        scrollDirection: 'vertical',
        effect: 'fadeIn',
    });

    Hammer.on(window, "load resize scroll", resize);
    window.viewportUnitsBuggyfill.init();
});

function searchItemContent(item, searchQuery) {
    switch (item.ItemType) {
        case "image":
        case "video":
        case "audio":
            return (
                String(item.Title).toLowerCase().includes(searchQuery) ||
                String(item.Description).toLowerCase().includes(searchQuery)
            );

        case "text":
            return (
                String(item.Title).toLowerCase().includes(searchQuery) ||
                String(item.Description).toLowerCase().includes(searchQuery) ||
                String(item.Content).toLowerCase().includes(searchQuery)
            );

        case "panes":
            return !!item.PaneElements.find(function(element) {
                return (
                    String(element.Title).toLowerCase().includes(searchQuery) ||
                    !!element.PaneItems.find(function (item) {
                        return searchItemContent(item, searchQuery);
                    })
                );
            });
        
        case "sequence":
            return !!item.SequenceElements.find(function(element) {
                return (
                    String(element.Title).toLowerCase().includes(searchQuery) ||
                    !!element.SequenceItems.find(function (item) {
                        return searchItemContent(item, searchQuery);
                    })
                );
            });
            
        case "twoColumn":
            return !!item.Columns.find(function (column) {
                return !!column.Items.find(function (item) {
                    return searchItemContent(item, searchQuery);
                })
            })

        default:
            // we don't know how to match any other kind of
            // content elements
            return false;
    }
}
