// Models
var ArticleModel = Backbone.Model.extend({});
var PeriodModel = Backbone.Model.extend({
	initialize: function(){
		// this.articels = new Articles(this.get('events'))
	}
});

// Collections
var ArticlesCollection = Backbone.Collection.extend({
	model:ArticleModel,
	initialize: function(){
		// console.log('Article', this);
	}
});
var PeriodsCollection = Backbone.Collection.extend({
	model:PeriodModel,
	initialize: function(){
		// console.log(this);
	}
});
(function(factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery', 'hammerjs'], factory);
	} else if (typeof exports === 'object') {
		factory(require('jquery'), require('hammerjs'));
	} else {
		factory(jQuery, Hammer);
	}
}(function($, Hammer) {
	function hammerify(el, options) {
		var $el = $(el);
		if(!$el.data("hammer")) {
			$el.data("hammer", new Hammer($el[0], options));
		}
	}

	$.fn.hammer = function(options) {
		return this.each(function() {
			hammerify(this, options);
		});
	};

	// extend the emit method to also trigger jQuery events
	Hammer.Manager.prototype.emit = (function(originalEmit) {
		return function(type, data) {
			originalEmit.call(this, type, data);
			$(this.element).trigger({
				type: type,
				gesture: data
			});
		};
	})(Hammer.Manager.prototype.emit);
}));

function convertHex(hex,opacity){
    r = parseInt(hex.substring(0,2), 16);
    g = parseInt(hex.substring(2,4), 16);
    b = parseInt(hex.substring(4,6), 16);

    result = 'rgba('+r+','+g+','+b+','+opacity/100+')';
    return result;
}